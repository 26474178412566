import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Download.module.sass";
import { ScrollParallax } from "../../../components";
import { WebApp, MobileApp } from "../../../assets/images/content";
import { Iphone, QR, Apple, Google, Android, DownloadApp } from "../../../assets/images/content";


const Download = () => {
  return (
    <div className={cn("section", styles.section)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h1 className={cn("h1", styles.title)}>
              Download
              <br />
              Velas Account
            </h1>
          
        </div>
        <div className={styles.wrap}>
          <div className={styles.item}>
            <div className={styles.row}>
              <div className={styles.col}>
                <div className={styles.details}>
                  {/* <div className={styles.number}>0{index + 1}.</div> */}
                  <h2 className={cn("h3", styles.category)}>Sign In. Anywhere.</h2>
                  <div className={styles.content}>Passwordless one-click login & All-in-one solution for interacting with Web 3.0.
Easily customize the token allowance and manage Dapps permissions. 
Start your journey with Velas Account and download the app today.</div>
                  
                  <div className={styles.btns}>
                    <Link className="button-small button-stroke" to="/download">
                      <img
                        src={DownloadApp}
                        alt="DownloadApp"
                        width="14"
                      />
                      <span>Download</span>
                    </Link>
                    {/* <Link className="button-small button-stroke" to="/">
                    <img
                      src={Google}
                      alt="Google"
                    />
                    <span>Google Play</span>
                    </Link>
                    <Link className="button-small button-stroke" to="/">
                    <img
                      src={Android}
                      alt="Android"
                    />
                    <span>Android</span>
                    </Link> */}
                  </div>
                  {/* <div className={styles.qrcontent}>
                    <div className={cn(styles.content, styles.divider)}>or</div>

                    <img className={styles.qr}
                      src={QR}
                      alt="qr"
                    />
                  </div> */}
                </div>
              </div>
              <div className={styles.col}>
                <img
                  src={Iphone}
                  alt="Devices"
                />
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>


  );
};

export default Download;
