import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import styles from "./Overview.module.sass";
import { Icon } from "../../../components";
import {
	Devices,
	Apple,
	Google,
	Android,
} from "../../../assets/images/content";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
	<button {...props}>{children}</button>
);

const Overview = () => {
	const settings = {
		infinite: true,
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		nextArrow: (
			<SlickArrow>
				<Icon name="arrow-next" size="14" />
			</SlickArrow>
		),
		prevArrow: (
			<SlickArrow>
				<Icon name="arrow-prev" size="14" />
			</SlickArrow>
		),
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
				},
			},
			{
				breakpoint: 100000,
				settings: "unslick",
			},
		],
	};

	return (
		<div className={cn("section", styles.section)}>
			<div className={cn("container", styles.container)}>
				<div className={styles.head}>
					<h1 className={cn("h1", styles.title)}>
						Download
						<br />
						Velas Account
					</h1>
				</div>
				<div className={styles.wrap}>
					<div className={styles.item}>
						<div className={styles.row}>
							<div className={styles.col}>
								<div className={styles.details}>
									{/* <div className={styles.number}>0{index + 1}.</div> */}
									<h2 className={cn("h3", styles.category)}>
										Sign In. Anywhere.
									</h2>
									<div className={styles.content}>
										All the power of Velas Account's cryptocurrency exchange, in
										the palm of your hand. Download the Velas Account mobile
										crypto trading app today.
									</div>
									<div className={styles.btns}>
										<div
											className="button-small button-stroke"
											onClick={() => {
												window.open(
													"https://apps.apple.com/ua/app/velas-account/id1563238927",
													"_blank"
												);
											}}
										>
											<img src={Apple} alt="Apple" width="14" />
											<span>AppStore</span>
										</div>

										{/* <Link className="button-small button-stroke" to="/">
                    <img
                      src={Google}
                      alt="Google"
                    />
                    <span>Google Play</span>
                    </Link> */}

										<div
											className="button-small button-stroke"
											to="/"
											onClick={() => {
												window.open(
													"https://play.google.com/store/apps/details?id=com.velas.account",
													"_blank"
												);
											}}
										>
											<img src={Google} alt="Google" />
											<span>Google Play</span>
										</div>

										<div
											className="button-small button-stroke"
											to="/"
											onClick={() => {
												window.open(
													"https://storage.googleapis.com/velas-static-public-data/VAccount-1.0.0-beta.apk",
													"_blank"
												);
											}}
										>
											<img src={Android} alt="Android" />
											<span>Android</span>
										</div>
									</div>
								</div>
							</div>
							<div className={styles.col}>
								<img src={Devices} alt="Devices" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Overview;
